.container {
  padding: 10px;
  max-width: 1024px;
  margin: auto;
}

router-outlet ~ * {
  position: relative;
  height: 100%;
  width: 100%;
}

.logo-100 {
  width: 100px;
  height: 100px;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
}

.loader {
  left: 50%;
  margin-left: -2em;
  font-size: 10px;
  border: .4em solid rgba(218, 219, 223, 1);
  border-left: .4em solid rgb(0, 68, 255);
  animation: spin 1.1s infinite linear;
}

.loader, .loader:after {
  border-radius: 50%;
  width: 4em;
  height: 4em;
  display: block;
  position: absolute;
  top: 40%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

/* Bootstrap. NOTE: It may be preferable to do this via the recommended SASS approach  */
a, .btn-link {
  text-decoration: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.badge {
  padding: 4px 8px !important;
  font-size: 14px !important;
  color: black;
}

.list-group {
  border-top-width: 0 !important;
}

.list-group-item {
  margin-bottom: 0 !important;
}

.list-group-item i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.5);
}

/* Required to allow the Google places autocomplete to show the results dropdown when used in a dialog */
.pac-container {
  z-index: 9999 !important;
}

.plus-button {
  background-color: white;
  border: none;
  border-radius: 50%;
  color: white;
  width: 25px;
  height: 25px;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.plus-button:before {
  content: "\f055";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 1.2em;
  color: #007bff;
}

.plus-button:hover:before {
  color: #005dc0; /* Lighter blue icon color on hover */
}

.plus-button:active:before {
  color: #1c8aff; /* Lighter blue icon color on hover */
}

.plus-button:focus,
.plus-button:active {
  outline: none;
  box-shadow: none;
}

.tip {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
}

@media (prefers-color-scheme: dark) {
  body {
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .text-muted {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  /* TODO: Fix "Update Account Details hover state */
  .btn:hover {
    color: #dbdbdb !important;
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
  }

  .close-button .fa-times {
    color: white;
  }

  .list-group-item {
    color: white !important;
    border: 1px solid rgba(255, 255, 255, 0.06) !important;
  }

  .list-group-item i {
    color: rgba(255, 255, 255, 0.5);
  }

  .list-group-item:not(.active) {
    background-color: #242426 !important;
  }

  .list-group-item-action:focus:not(.active), .list-group-item-action:hover:not(.active) {
    opacity: 0.8;
  }

  .modal-content {
    background-color: #242426 !important;
  }

  .form-control[readonly] {
    background-color: #949494 !important;
  }

  .tip {
    color: rgba(255, 255, 255, 0.5);
  }
}
